import "./MonthlyToAnnualUpgradeCard.scss";
import { Subscription, SubscriptionChangeType, cancelPendingChange, cancelStatusStateSelector, initMonthlyToAnnualUpgrade, monthlyToAnnualUpgradeStateSelector } from "../../features/user/subscriptionsSlice";
import iconBillPaper from "../../assets/media/icons/bill_paper.svg";
import { useDispatch, useSelector } from "react-redux";
import { getErrorsCompiled } from "../../utils/uiHelpers";

interface MonthlyToAnnualUpgradeCardProps {
    subscription: Subscription;
}

export const MonthlyToAnnualUpgradeCard = ({ subscription: sub }: MonthlyToAnnualUpgradeCardProps) => {
    const dispatch = useDispatch();
    const upgradeState = useSelector(monthlyToAnnualUpgradeStateSelector(sub.id));
    const cancelState = useSelector(cancelStatusStateSelector(sub.id));

    return (
        <div className="card">
            <div className="header">
                <img src={iconBillPaper} />
                <h4 className="muted">Convert to permanent</h4>
            </div>
            <div id="upgradeToSuiteCardContent">
                {sub.pendingChange?.changeType === SubscriptionChangeType.MonthlyToAnnualUpgrade &&
                    <>
                        <p className="mb-1">
                            <span className="danger bold">NOTE:</span> Once the quote is paid all your licenses will automatically convert to permanent.
                        </p>
                        <button className="btn success"
                            onClick={() => window.open(upgradeState?.data?.quoteUrl, "_blank")}>Pay quote now</button>
                        <button className="btn danger"
                            disabled={cancelState?.value === "pending"}
                            onClick={() => dispatch(cancelPendingChange({ subId: sub.id }))}>Cancel quote</button>
                    </>}
                {sub.pendingChange?.changeType !== SubscriptionChangeType.MonthlyToAnnualUpgrade &&
                    <>
                        <p className="mb-1">Convert your licenses to permanent by upgrading to an annual maintenance plan.</p>
                        <button className="btn primary"
                            disabled={upgradeState?.status.value === "pending" || sub.pendingChange !== null}
                            onClick={() => dispatch(initMonthlyToAnnualUpgrade({ subId: sub.id }))}>Upgrade to permanent now</button>
                    </>}
            </div>
        </div>
    )
};