import "./SideBar.scss";
import logo from "../assets/media/logos/jangafx.png";
import iconPlans from "../assets/media/icons/plans.svg";
import iconUser from "../assets/media/icons/user.svg";
import iconUsers from "../assets/media/icons/users.svg";
import iconMarketplace from "../assets/media/icons/marketplace.svg";
import { LinkProps, NavLink, useLocation } from "react-router-dom";
import SideBarAccordion from "./SideBarAccordion";
import ApplicationRoles from "../data/ApplicationRoles";
import { useSelector } from "react-redux";
import { uiGlobalStateSelector } from "../features/ui/globalUiSlice";
import { userInfoSelector } from "../features/user/userSlice";
import SideBarLink from "./SideBarLink";

export function CustomLink({ activeClass = "active", children, to, ...props }: LinkProps & { activeClass?: string }) {
    return (
        <NavLink
            className={({ isActive }) => isActive ? activeClass : ""}
            to={to}
            {...props}>
            {children}
        </NavLink>
    );
}

const SideBar = () => {
    const { pathname } = useLocation();
    const uiState = useSelector(uiGlobalStateSelector);
    const userInfo = useSelector(userInfoSelector);

    return (
        <div className={`sidebar ${uiState.sidebarOpened ? "visible" : "hidden"}`}>
            <img src={logo} className="logo" alt="JangaFX Logo" />
            <nav>
                <SideBarLink to={"/"} icon={<img src={iconPlans} alt="Home" />}>Home</SideBarLink>
                <SideBarAccordion title={"Account"} defaultIsExpanded={true}
                    isExpanded={pathname.startsWith("/info") || pathname.startsWith("/security") || pathname.startsWith("/account-linking")} icon={<img src={iconUser} alt="Account" />}>
                    <CustomLink to={"/info"}>Personal</CustomLink>
                    <CustomLink to={"/security"}>Password & MFA</CustomLink>
                    <CustomLink to={"/account-linking"}>Account Linking</CustomLink>
                </SideBarAccordion>
                <SideBarAccordion title={"My purchases"} defaultIsExpanded={true}
                    isExpanded={pathname.startsWith("/purchases")} icon={<img src={iconMarketplace} />}>
                    <CustomLink to={"/purchases/subscriptions"}>Subscriptions</CustomLink>
                    <CustomLink to={"/purchases/marketplace"}>Marketplace</CustomLink>
                </SideBarAccordion>
                {userInfo?.groups.includes(ApplicationRoles.Administrator) &&
                    <SideBarAccordion title={"Admin Area"} isExpanded={pathname.startsWith("/admin")} icon={<img src={iconUsers} alt="Admin Area" />}>
                        <CustomLink to={"/admin/users"}>Users</CustomLink>
                    </SideBarAccordion>}
            </nav>
        </div >
    )
}

export default SideBar;