import './App.scss';
import './styles/theme.scss';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import RequireAuth from './auth/RequireAuth';
import MainLayout from './components/MainLayout';
import PersonalPage from './components/pages/account/PersonalPage';
import SecurityPage from './components/pages/account/SecurityPage';
import AdminHomePage from './components/pages/admin/AdminHomePage';
import ApplicationRoles from './data/ApplicationRoles';
import ProtectedView from './security/ProtectedView';
import AuthenticationLayout from './auth/AuthenticationLayout';
import { RegisterTab } from './auth/RegisterTab';
import { LoginTab } from './auth/LoginTab';
import { ForgotPasswordTabLayout } from './auth/ForgotPasswordTab';
import { lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideSidebar } from './features/ui/globalUiSlice';
import SubscriptionsPage from './components/pages/purchases/SubscriptionsPage';
import SubscriptionPage from './components/pages/purchases/SubscriptionPage';
import useAuthenticator from './features/user/authentication';
import LicenseDetails from './components/LicenseDetails';
import LicensesDetailedList from './components/pages/purchases/LicensesDetailedList';
import ModifySubscriptionSeatingDetails from './components/pages/purchases/ModifySubscriptionSeatingDetails';
import { ConfirmUser } from './auth/ConfirmUser';
import ReactGA from "react-ga4";
import { userInfoSelector } from './features/user/userSlice';
import AdminUsersPage from './components/pages/admin/AdminUsersPage';
import MarketplacePurchasesPage from './components/pages/purchases/MarketplacePurchasesPage';
import { DiscordLinkWorkerPage } from './components/worker-pages/DiscordLinkWorkerPage';
import { AccountLinkingPage } from './components/pages/account/AccountLinkingPage';
import HomePage from './components/pages/HomePage';

const AdminLayout = lazy(() => import("./components/AdminLayout"));

ReactGA.initialize("G-V2HRCZXR54", {
  testMode: process.env.NODE_ENV === "development",
});

const DevelopmentHeader = () => {
  return (
    <div id="devHeader" className="text-center">
      DEVELOPMENT
    </div>
  );
}

function App() {
  const locaton = useLocation();
  const dispatch = useDispatch();
  const authStatus = useAuthenticator();
  const userInfo = useSelector(userInfoSelector);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location.pathname]);

  useEffect(() => {
    dispatch(hideSidebar());
  }, [dispatch, locaton.pathname]);

  if (authStatus.status !== "authenticated") {
    return (
      <>
        {process.env.NODE_ENV === "development" && <DevelopmentHeader />}
        <Routes>
          <Route path="/" element={<AuthenticationLayout />}>
            <Route index element={<Navigate to={"/signin"} />} />

            <Route path="/signin" element={<LoginTab />} />
            <Route path="/signup" element={<RegisterTab />} />
            <Route path="/forgot-password" element={<ForgotPasswordTabLayout />} />
            <Route path="/confirm-user" element={<ConfirmUser />} />

            <Route path="*" element={<Navigate to={"/signin"} />} />
          </Route>
        </Routes>
      </>
    )
  }

  return (
    <>
      {process.env.NODE_ENV === "development" && <DevelopmentHeader />}
      <Routes>
        <Route path="/" element={
          <RequireAuth>
            <MainLayout />
          </RequireAuth>
        }>
          <Route index element={<HomePage />} />
          <Route path="info" element={<PersonalPage />} />
          <Route path="security" element={<SecurityPage />} />
          <Route path="account-linking" element={<AccountLinkingPage />} />
          <Route path="purchases/marketplace" element={<MarketplacePurchasesPage />} />
          <Route path="purchases/subscriptions" element={<SubscriptionsPage />} />
          <Route path="purchases/subscriptions/:subId" element={<SubscriptionPage />}>
            <Route path="licenses" element={<LicensesDetailedList />}>
              <Route path=":licenseId" element={<LicenseDetails />} />
            </Route>
            <Route path="modify-seating" element={<ModifySubscriptionSeatingDetails />} />
          </Route>

          {/* Worker routes */}
          <Route path="link/discord" element={<DiscordLinkWorkerPage />} />

          {/* Admin area */}
          <Route path="admin" element={
            <ProtectedView userInfo={userInfo} groups={[ApplicationRoles.Administrator]}>
              <AdminLayout />
            </ProtectedView>
          }>
            <Route path="home" element={<AdminHomePage />} />
            <Route path="users" element={<AdminUsersPage />} />
          </Route>


          <Route path="*" element={<Navigate to={"/"} />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
