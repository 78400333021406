import "./MarketplacePurchasesPage.scss";
import { useDispatch, useSelector } from "react-redux";
import { MarketplaceAssetPackProduct, MarketplaceProductType, PurchaserLegalStatus, downloadProductResource, marketplaceDownloadStatusSelector, marketplaceProductsSelector, userPurchasesSelector, userPurchasesStatusSelector } from "../../../features/user/marketplaceSlice";
import { useMemo, useState } from "react";
import Button from "../../Button";
import Dropdown from "../../Dropdown";

export const MarketplacePurchasesPage = () => {
    const [userStatus, setUserStatus] = useState<PurchaserLegalStatus>("indie");
    const userPurchasesStatus = useSelector(userPurchasesStatusSelector);
    const userPurchases = useSelector(userPurchasesSelector);
    const marketplaceProducts = useSelector(marketplaceProductsSelector);

    const productCards = useMemo(() => {
        const purchasedProductIds = userPurchases.map(p => p.productId);
        const cards = Object.keys(marketplaceProducts).map(pkey => {
            const prod = marketplaceProducts[pkey];
            if (prod.type === MarketplaceProductType.assetPack) {
                return <AssetPackProductCard key={prod.productId} userStatus={userStatus} owned={purchasedProductIds.includes(prod.productId)} product={prod as MarketplaceAssetPackProduct} />
            } else {
                throw new Error("Unhandled product type.");
            }
        });

        return cards;
    }, [userPurchases, userStatus]);

    return (
        <div id="marketplacePurchasesPage">
            <div className="header">
                <h2>Marketplace</h2>
                <p className="muted">You can see your purchased assets such as packs and various marketplace products available on our store.</p>
            </div>
            <hr className="big" />
            <div className="d-flex flex-direction-row flex-gap-small p-1">
                <p className="m-0">I am {userStatus === "indie" || userStatus === "enterprise" ? "an" : "a"}</p>
                <div>
                    <Dropdown.Container elements={[
                        {
                            value: "Indie",
                            onClick: () => setUserStatus("indie"),
                        },
                        {
                            value: "Studio",
                            onClick: () => setUserStatus("studio"),
                        },
                        {
                            value: "Enterprise",
                            onClick: () => setUserStatus("enterprise"),
                        }
                    ]} header={<span>{userStatus}</span>} />
                </div>
            </div>
            <div id="productsContainer">
                {userPurchasesStatus.value === "failure" && <p>We could not fetch your purchases, please contact our support.</p>}
                {userPurchasesStatus.value === "pending" && <p>We're looking for your purchases in our basket...</p>}
                {userPurchasesStatus.value === "success" &&
                    <>
                        {productCards.length > 0 && productCards}
                        {productCards.length === 0 && <p>No purchases have been found on this account. If you've recently bought a subscription, please refer to the <span className="bold">Subscriptions</span> tab.</p>}
                    </>}
            </div>
        </div>
    )
};

interface AssetPackProductCardProps {
    product: MarketplaceAssetPackProduct;
    owned: boolean;
    userStatus: PurchaserLegalStatus;
}

const AssetPackProductCard = ({ product, owned, userStatus }: AssetPackProductCardProps) => {
    const dispatch = useDispatch();
    const downloadStatus = useSelector(marketplaceDownloadStatusSelector);
    return (
        <div className={`product-card ${owned ? "owned" : ""}`}>
            {owned &&
                <div className="ownedbanner">
                    <p>You own this product</p>
                </div>}

            <div className="header">
                <img src={product.thumbnailUrl} alt={product.name} />
            </div>
            <div className="p-1">
                <h4 className="m-1-top m-2-bottom text-center">{product.name}</h4>
                <div className="d-flex flex-direction-row flex-gap-small w-100">
                    <a className="btn tertiary flex-grow-1" href={product.productPageUrl} target="_blank">View page</a>
                    {owned && <Button color="primary" onClick={_ => dispatch(downloadProductResource({ product: product }))} disabled={downloadStatus.value === "pending"}>Download</Button>}
                    {!owned && <a className="btn primary" href={product.purchaseLinks[userStatus]} target="_blank">Buy now</a>}
                </div>
            </div>
        </div>
    )
}

export default MarketplacePurchasesPage;