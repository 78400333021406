import { useDispatch, useSelector } from "react-redux";
import Button from "../../Button";
import Input from "../../Input";
import "./AdminUsersPage.scss";
import { searchUserThunk, searchedUserSelector } from "../../../features/admin/adminUsersSlice";
import { EventHandler, useCallback, useState } from "react";
import AdminUserViewModelCard from "../../admin/AdminUserViewModelCard";
import { getErrorsCompiled } from "../../../utils/uiHelpers";

const AdminUsersPage = () => {
    const dispatch = useDispatch();
    const userSearchState = useSelector(searchedUserSelector);

    const [searchNeedleVal, setSearchVal] = useState<string>();

    const onFormSubmitted = useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(searchUserThunk({
            searchNeedle: searchNeedleVal
        }));
    }, [searchNeedleVal]);

    return (
        <div id="adminUserPage">
            <div className="d-flex flex-direction-column flex-gap-medium">
                <form onSubmit={onFormSubmitted}>
                    <div className="input-group">
                        <label>Search a user in the database</label>
                        <div className="d-flex flex-direction-row flex-gap-medium">
                            <Input style={{ flexGrow: 1 }} placeholder="Type the user email address or user ID..."
                                disabled={userSearchState.status.value === "pending"}
                                onChange={e => setSearchVal(e.target.value)} />
                            <Button type="submit" color={"primary"} disabled={userSearchState.status.value === "pending"}>Search User</Button>
                        </div>
                        {userSearchState.status.value === "failure" && getErrorsCompiled(userSearchState.status.error).map(e => <p className="danger">{e}</p>)}
                    </div>
                </form>
                {userSearchState.status.value === "pending" && <p>Looking for user...</p>}
                {userSearchState.status.value === "success" && <AdminUserViewModelCard user={userSearchState.data} />}
            </div>
        </div>
    );
}

export default AdminUsersPage;