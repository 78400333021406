/* eslint-disable @typescript-eslint/no-misused-promises */
import { QRCodeSVG } from "qrcode.react";
import { useCallback, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Button";
import Input from "../../Input";
import { changePasword, mfaUpdateStateSelector, passwordChangeStateSelector, setMfaPreference, userInfoSelector, verifyTotpToken } from "../../../features/user/userSlice";
import "./SecurityPage.scss";

interface IPasswordChangeFormInputs {
    oldPassword: string;
    newPassword: string;
    newPasswordConfirmation: string;
}

interface IMfaTotpVerificationInputs {
    totpCode: string;
}

const SecurityPage = () => {
    return (
        <div id="securityPage">
            <PasswordChangeForm />
            <hr />
            <MfaForm />
        </div>
    );
};

const PasswordChangeForm = () => {
    const dispatch = useDispatch();
    const pwChangeState = useSelector(passwordChangeStateSelector);
    const { register, watch, getValues, control, handleSubmit, formState: { errors }, setValue, reset } = useForm<IPasswordChangeFormInputs>({
        reValidateMode: "onChange",
        defaultValues: {
            oldPassword: "",
            newPassword: "",
            newPasswordConfirmation: "",
        }
    });

    const onPasswordChangeSubmit = useCallback<SubmitHandler<IPasswordChangeFormInputs>>((data) => {
        dispatch(changePasword({
            oldPassword: watch("oldPassword"),
            newPassword: watch("newPassword"),
        }))
    }, [dispatch, watch]);

    useEffect(() => {
        if (pwChangeState.status === "success") {
            reset();
        }
    }, [pwChangeState.status, reset]);

    return (
        <>
            <h3>Change Password</h3>
            <p className="muted">You will have to confirm with a code the change of your password.</p>
            <hr />
            <form id="changePasswordForm" onSubmit={handleSubmit(onPasswordChangeSubmit)}>
                <div className="input-group inline">
                    <label>Current Password</label>
                    <div>
                        <Controller
                            name="oldPassword"
                            control={control}
                            rules={{
                                required: true,
                                maxLength: 256,
                                pattern: /[\S]+/
                            }}
                            render={({ field }) =>
                                <Input {...field}
                                    type={"password"}
                                    autoComplete="current-password"
                                    placeholder="Type your current password..."
                                    error={pwChangeState.error?.code === "NotAuthorizedException" || errors.oldPassword !== undefined} />}
                        />
                        {errors.oldPassword?.type === "required" &&
                            <p className="danger">The current password is mandatory.</p>}
                        {errors.oldPassword?.type === "maxLength" &&
                            <p className="danger">The password cannot be longer than 256 characters.</p>}
                        {errors.oldPassword?.type === "pattern" &&
                            <p className="danger">{errors.oldPassword.message}</p>}
                        {errors.oldPassword?.type === "validate" &&
                            <p className="danger">{errors.oldPassword.message}</p>}
                    </div>
                </div>
                <hr />
                <div className="input-group inline">
                    <label>New Password</label>
                    <div>
                        <Controller
                            name="newPassword"
                            control={control}
                            rules={{
                                required: true,
                                maxLength: 256,
                                minLength: 8,
                                pattern: /[\S]+/
                            }}
                            render={({ field }) =>
                                <Input {...field}
                                    type={"password"}
                                    autoComplete="new-password"
                                    placeholder="Type your new password..."
                                    error={pwChangeState.error?.code === "InvalidPasswordException" || errors.newPassword !== undefined} />}
                        />

                        {errors.newPassword?.type === "required" &&
                            <p className="danger">The password is mandatory.</p>}
                        {errors.newPassword?.type === "minLength" &&
                            <p className="danger">The password must contain at least 8 characters.</p>}
                        {errors.newPassword?.type === "maxLength" &&
                            <p className="danger">The password cannot be longer than 256 characters.</p>}
                        {errors.newPassword?.type === "pattern" &&
                            <p className="danger">{errors.newPassword.message}</p>}
                        {errors.newPassword?.type === "validate" &&
                            <p className="danger">{errors.newPassword.message}</p>}
                    </div>
                </div>
                <hr />
                <div className="input-group inline">
                    <label>Confirm Password</label>
                    <div>
                        <Controller
                            name="newPasswordConfirmation"
                            control={control}
                            rules={{
                                required: true,
                                maxLength: 256,
                                minLength: 8,
                                pattern: /[\S]+/,
                                validate: (password) => {
                                    if (getValues().newPassword === password) return true;

                                    return "Passwords do not match.";
                                }
                            }}
                            render={({ field }) =>
                                <Input {...field}
                                    type={"password"}
                                    autoComplete="new-password"
                                    placeholder="Type your new password again..."
                                    error={pwChangeState.error?.code === "InvalidPasswordException" || errors.newPasswordConfirmation !== undefined} />}
                        />

                        {errors.newPasswordConfirmation?.type === "required" &&
                            <p className="danger">The password confirmation is mandatory.</p>}
                        {errors.newPasswordConfirmation?.type === "maxLength" &&
                            <p className="danger">The password cannot be longer than 256 characters.</p>}
                        {errors.newPasswordConfirmation?.type === "pattern" &&
                            <p className="danger">{errors.newPasswordConfirmation.message}</p>}
                        {errors.newPasswordConfirmation?.type === "validate" &&
                            <p className="danger">{errors.newPasswordConfirmation.message}</p>}
                    </div>
                </div>

                <div>
                    {pwChangeState.status === "error" &&
                        <p className="danger">{pwChangeState.error?.message}</p>}
                    <Button type="submit" color={"primary"} disabled={pwChangeState.status === "pending"}>
                        {pwChangeState.status === "pending" && <span>Changing Password</span>}
                        {pwChangeState.status !== "pending" && <span>Change Password</span>}
                    </Button>
                </div>
            </form>
        </>
    )
};

const MfaFormWithMfaDisabled = () => {
    const dispatch = useDispatch();
    const mfaUpdateState = useSelector(mfaUpdateStateSelector);
    const { register, watch, getValues, control, handleSubmit, formState: { errors }, setValue, reset } = useForm<IMfaTotpVerificationInputs>({
        reValidateMode: "onChange",
        defaultValues: {
            totpCode: ""
        }
    });

    const onSubmit = useCallback<SubmitHandler<IMfaTotpVerificationInputs>>((data) => {
        dispatch(verifyTotpToken({
            totpToken: data.totpCode
        }));
    }, [dispatch]);

    const onPairClicked = useCallback(() => {
        dispatch(setMfaPreference({ preference: "SOFTWARE_TOKEN_MFA" }));
    }, [dispatch]);

    useEffect(() => {
        if (mfaUpdateState.status === "success") {
            reset();
        }
    }, [mfaUpdateState.status, reset]);

    return (
        <div>
            {mfaUpdateState.step === "NONE" &&
                <Button type="submit" color="primary" disabled={mfaUpdateState.status === "pending"} onClick={onPairClicked}>
                    Pair MFA device
                </Button>
            }

            {mfaUpdateState.step === 'AWAITING_TOTP_VERIFICATION' &&
                <>
                    <p>Scan the QR code below in the application of your choice. Various providers are available out there such as <a href="https://support.google.com/accounts/answer/1066447?hl=en&ref_topic=2954345" target={"_blank"}>Google Authenticator</a>, <a href="https://www.microsoft.com/en-us/security/mobile-authenticator-app" target={"_blank"}>Microsoft Authenticator</a>, <a href="https://authy.com/" target={"blank"}>Authy</a> and many more.</p>

                    <form id="mfaEnableForm" onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            {mfaUpdateState.totpUri &&
                                <QRCodeSVG value={mfaUpdateState.totpUri} includeMargin={true} />}
                            {!mfaUpdateState.totpUri &&
                                <p className="danger">Could not generate QR code, please report this issue to support@jangafx.com</p>}
                        </div>

                        <div>
                            <div className="input-group">
                                <label>2FA Code</label>
                                <Controller
                                    name="totpCode"
                                    rules={{
                                        required: true,
                                        minLength: 6
                                    }}
                                    control={control}
                                    render={({ field }) =>
                                        <Input {...field}
                                            type={"text"}
                                            placeholder="Type your MFA code..."
                                            error={errors.totpCode?.type !== undefined || mfaUpdateState.error !== undefined}
                                            disabled={mfaUpdateState.status === "pending"} />}
                                />
                                {errors.totpCode?.type === "required" &&
                                    <p className="danger">You must provide the MFA code from your device.</p>}
                                {errors.totpCode?.type === "minLength" &&
                                    <p className="danger">The MFA code must be at least 6 characters long.</p>}
                                {mfaUpdateState.error &&
                                    <p className="danger">{mfaUpdateState.error.message}</p>}
                            </div>
                            <Button type="submit" style={{ width: "100%" }} color="primary" disabled={mfaUpdateState.status === "pending"}>
                                Confirm code
                            </Button>
                        </div>
                    </form>
                </>}

        </div >
    )
};

const MfaFormWithMfaEnabled = () => {
    const dispatch = useDispatch();
    const mfaUpdateState = useSelector(mfaUpdateStateSelector);

    const onDisabledClicked = useCallback(() => {
        dispatch(setMfaPreference({ preference: "NOMFA" }));
    }, [dispatch]);

    return (
        <div>
            <small>You have multi factor authentication enabled. <br />You are required to introduce a one time-password when loggin in, automatically generated in the application of your choice.</small>

            <div className="input-group">
                {mfaUpdateState.status === "error" &&
                    <p className="danger">{mfaUpdateState.error?.message}</p>}
                <div style={{ marginTop: "10px" }}>
                    <Button type="submit" onClick={onDisabledClicked} color={"danger"} disabled={mfaUpdateState.status === "pending"}>
                        Disable MFA
                    </Button>
                </div>
            </div>
        </div>
    );
};

const MfaForm = () => {
    const userInfo = useSelector(userInfoSelector);

    return (
        <>
            <h3>Multi Factor Authentication</h3>
            <p className="muted">Enhance your account security by setting additional steps when signin in.</p>
            {userInfo?.mfaEnabled ? <MfaFormWithMfaEnabled /> : <MfaFormWithMfaDisabled />}
        </>
    )
};

export default SecurityPage;