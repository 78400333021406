import "./SubscriptionPage.scss";

import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import { buyMaintenance, cancelPendingChange, cancelStatusStateSelector, cancelSubscription, initSuiteUpgrade, maintenancePurchaseStateSelector, resumeStatusStateSelector, resumeSubscription, Subscription, SubscriptionChangeType, SubscriptionState, subSelector, suiteUpgradeStateSelector } from "../../../features/user/subscriptionsSlice";
import { getProductName, getProductTarget } from "../../../utils/subHelpers";
import iconLicense from "../../../assets/media/icons/license.svg";
import iconLoop from "../../../assets/media/icons/loop.svg";
import iconBillPaper from "../../../assets/media/icons/bill_paper.svg";
import alertNegative from "../../../assets/media/icons/alert_negative.svg";

import { licensesStateSelector } from "../../../features/user/licensesSlice";
import TabGroup from "../../TabGroup";
import Tab from "../../Tab";
import Dropdown from "../../Dropdown";
import Button from "../../Button";
import { openFastSpringAccPlatform, openFastSpringPlatformStateSelector } from "../../../features/user/userSlice";
import { UpgradeToSuiteCard } from "../../subscription/UpgradeToSuiteCard";
import { MonthlyToAnnualUpgradeCard } from "../../subscription/MonthlyToAnnualUpgradeCard";

const SubscriptionPage = () => {
    const { subId } = useParams();
    const sub = useSelector(subSelector(subId));

    const navigate = useNavigate();

    if (subId === undefined) {
        navigate("/purchases/subscriptions");
        return <></>;
    }

    const prodName = useMemo(() => sub === undefined ? "" : getProductName(sub), [sub]);
    const prodTarget = useMemo(() => sub === undefined ? "" : getProductTarget(sub), [sub]);

    if (sub === undefined) {
        return <div className="p-2">Looking for this subscription in our stash ... 🔍</div>;
    }

    return (
        <div id="subscriptionPage">
            <h2 className="header">{prodName} {prodTarget}</h2>
            <p className="muted sub-header" style={{ marginBottom: 32 }}>{sub.isFloating ? "Floating seats" : "Node-locked keys"}</p>
            <div className="subscriptionPage-cards-container">
                <LicensesKeysStatusCard subscription={sub} />
                {sub.state !== SubscriptionState.Deactivated && <NexyPaymentCard subscription={sub} />}
                <UpgradeToSuiteCard sub={sub} />
                {sub.canUpgradeMonthlyToAnnual && <MonthlyToAnnualUpgradeCard subscription={sub} />}
                {sub.state !== SubscriptionState.Deactivated && <SubscriptionProgressCard subscription={sub} />}
                {sub.state === SubscriptionState.Deactivated && sub.productCode.includes("annual") && <MaintenancePurchaseCard subscription={sub} />}
            </div>
            <div id="subPageContainer">
                <div style={{ gridArea: "tabs" }}>
                    <TabGroup>
                        <NavLink to={"licenses"}>{({ isActive }) => <Tab title={`Manage`} isSelected={isActive} />}</NavLink>
                        <NavLink to={"modify-seating"}>{({ isActive }) => <Tab title={`Buy ${sub.isFloating ? "seats" : "keys"}`} isSelected={isActive} />}</NavLink>
                    </TabGroup>
                </div>
                <div style={{ gridArea: "outlet" }}>
                    <Outlet />
                </div>
            </div>
        </div>
    )
};

interface LicensesKeysStatusCardProps {
    subscription: Subscription;
}

const LicensesKeysStatusCard = ({ subscription: sub }: LicensesKeysStatusCardProps) => {
    const licsState = useSelector(licensesStateSelector);
    const assignedKeysCount = useMemo(() => {
        if (licsState.licenses.getStatus.value !== "success") return "...";

        const count = sub?.licensesIds.reduce((acc, licId) => acc + (licsState.licenses.entities?.[licId].sharedTo.length === 0 ? 0 : 1), 0) ?? 0;
        return count;
    }, [sub, licsState.licenses.getStatus.value]);

    return (
        <div className="card">
            <div className="header">
                <img src={iconLicense} />
                <h4 className="muted">License Keys Status</h4>
            </div>
            <div id="licensesStatusCardContent">
                <div className="content">
                    <h3 className="muted">Available</h3>
                    <h2>{sub.licensesIds.length}</h2>
                </div>
                <div className="content">
                    <h3 className="muted">Assigned</h3>
                    <h2>{assignedKeysCount}</h2>
                </div>
            </div>
        </div>
    );
}

interface NextPaymentCardProps {
    subscription?: Subscription;
}

const NexyPaymentCard = ({ subscription: sub }: NextPaymentCardProps) => {
    const dispatch = useDispatch();
    const cancelState = useSelector(cancelStatusStateSelector(sub?.id));
    const resumeState = useSelector(resumeStatusStateSelector(sub?.id));
    const openFsPlatformStatus = useSelector(openFastSpringPlatformStateSelector);

    const currFormatter = useMemo(() => sub && Intl.NumberFormat("en-US", { style: "currency", currency: sub.currency ?? "USD" }), [sub]);
    /*  const dropdownItems = useMemo<Dropdown.ContentElement[]>(() => {
         const elems: Dropdown.ContentElement[] = [];
         if (sub?.state === SubscriptionState.Canceled) {
             elems.push({
                 value: "Resume Subscription",
                 onClick: (ev) => {
                     dispatch(resumeSubscription({ subId: sub.id }));
                 },
                 disabled: resumeState?.value === "pending",
             });
         } else if (sub?.state === SubscriptionState.Active) {
             elems.push({
                 value: "Cancel Subscription",
                 onClick: (ev) => {
                     dispatch(cancelSubscription({ subId: sub.id }))
                 },
                 disabled: cancelState?.value === "pending",
             });
         }
 
         elems.push({
             value: "Change Payment Details",
             // eslint-disable-next-line @typescript-eslint/no-unused-vars
             onClick: ev => ,
             disabled: 
         });
 
         elems.push({
             value: "Pay Now",
             // eslint-disable-next-line @typescript-eslint/no-unused-vars
             onClick: ev => dispatch(openFastSpringAccPlatform({ intent: `paydown-subscription-${sub?.id ?? "unknown-sub-id"}` })),
             disabled: openFsPlatformStatus.value === "pending"
         });
 
         return elems;
     }, [sub, cancelState?.value, resumeState?.value, openFsPlatformStatus.value]); */

    return (
        <div className="card" style={{gridRow: "1 / span 2"}}>
            <div className="header">
                <img src={iconBillPaper} />
                <h4 className="muted">Next Payment</h4>
            </div>
            <div id="nextPaymentCardContent">
                <p className="muted">Amount</p>
                {sub && sub.state === SubscriptionState.Active && <p>{currFormatter?.format(sub.nextChargeInfo.total)}</p>}
                {sub && sub.state !== SubscriptionState.Active && <p>-</p>}
                {!sub && <p>...</p>}

                <p className="muted">Due by</p>
                {sub && sub.state === SubscriptionState.Active && <p>{sub.nextChargeInfo.chargeDate && new Date(sub.nextChargeInfo.chargeDate).toDateString()}</p>}
                {sub && sub.state !== SubscriptionState.Active && <p>-</p>}
                {!sub && <p>...</p>}
            </div>
            <div className="d-flex flex-direction-column flex-gap-small mt-auto mb-0">
                <div className="d-flex flex-direction-row flex-gap-small">
                    <button className="btn primary flex-grow-1" disabled={openFsPlatformStatus.value === "pending"}
                        onClick={_ => dispatch(openFastSpringAccPlatform({ intent: `paydown-subscription-${sub?.id ?? "unknown-sub-id"}` }))}>Pay now</button>
                    {sub && sub.state !== SubscriptionState.Canceled &&
                        <Button color={"danger"} disabled={cancelState?.value === "pending"}
                            onClick={_ => dispatch(cancelSubscription({ subId: sub.id }))}>Cancel subscription</Button>}
                    {sub && sub.state === SubscriptionState.Canceled &&
                        <Button color={"success"} disabled={resumeState?.value === "pending"}
                            onClick={_ => dispatch(resumeSubscription({ subId: sub.id }))}>Resume subscription</Button>}
                </div>
                <Button color={"primary"} disabled={openFsPlatformStatus.value === "pending"}
                    onClick={_ => dispatch(openFastSpringAccPlatform({ intent: "payment-details" }))}>Change Payment Details</Button>
            </div>
        </div>
    );
}

interface SubscriptionProgressCardProps {
    subscription?: Subscription;
}

const SubscriptionProgressCard = ({ subscription: sub }: SubscriptionProgressCardProps) => {
    const [ctx, setCtx] = useState<{
        progress: number;
        rangeBegin: string;
        rangeEnd: string;
    }>({ progress: 0, rangeBegin: "...", rangeEnd: "..." });

    useEffect(() => {
        if (sub === undefined) return;

        const nowDt = new Date();
        const bgDt = new Date(sub.beginAt);
        let endDt: Date;
        if (sub.state === SubscriptionState.Active) {
            endDt = new Date(sub.nextChargeInfo.chargeDate);
        } else if (sub.state === SubscriptionState.Canceled && sub.endAt) {
            endDt = new Date(sub.endAt);
        } else if (sub.state === SubscriptionState.Deactivated && sub.deactivatedAt) {
            endDt = new Date(sub.deactivatedAt);
        } else {
            endDt = bgDt;
        }

        if (nowDt > endDt) {
            setCtx({
                progress: 100,
                rangeBegin: bgDt.toDateString(),
                rangeEnd: endDt.toDateString(),
            });
            return;
        }

        const relativeNowDt = nowDt.getTime() - bgDt.getTime();
        const diff = relativeNowDt / (endDt.getTime() - bgDt.getTime());
        setCtx({
            progress: diff * 100,
            rangeBegin: bgDt.toDateString(),
            rangeEnd: endDt.toDateString(),
        });
    }, [sub?.beginAt, sub?.nextChargeInfo.chargeDate]);

    return (
        <div className="card">
            <div className="header">
                <img src={iconLoop} />
                <h4 className="muted">Subscription Progress</h4>
            </div>
            <div id="subProgressCardContent">
                <div id="subProgressCardContentBar" className={sub?.state === SubscriptionState.Active ? "active" : "inactive"}>
                    <div style={{ width: `${ctx.progress}%` }}></div>
                </div>
                <div>
                    {sub && <p>{ctx.rangeBegin}</p>}
                    {!sub && <p>...</p>}

                    {sub && <p>{ctx.rangeEnd}</p>}
                    {!sub && <p>...</p>}
                </div>
            </div>
        </div>
    );
}

interface MaintenancePurchaseCardProps {
    subscription: Subscription;
}

const MaintenancePurchaseCard = ({ subscription: sub }: MaintenancePurchaseCardProps) => {
    const dispatch = useDispatch();
    const mntBuyState = useSelector(maintenancePurchaseStateSelector(sub.id));

    return (
        <div className="card">
            <div className="header">
                <img src={alertNegative} />
                <h4 className="muted">Maintenance Status</h4>
            </div>
            <div id="maintenancePurchaseCardContent">
                {!sub.pendingChange &&
                    <>
                        <p>Purchase maintenance to unlock latest updates.</p>
                        <Button color="primary"
                            onClick={() => dispatch(buyMaintenance({ subId: sub.id }))}
                            disabled={mntBuyState?.status.value === "pending"}>Buy Maintenance</Button>
                        {mntBuyState?.status.value === "failure" &&
                            <small className="danger">An error occured while generating the quote.</small>}
                    </>}

                {sub.pendingChange?.changeType === SubscriptionChangeType.MaintenancePurchase &&
                    <>
                        <p>You have a pending maintenance purchase!</p>
                        <div className="row">
                            <button className="btn success col" onClick={() => window.open(sub.pendingChange?.quoteUrl)}>Pay invoice</button>
                            <button className="btn danger col" onClick={() => dispatch(cancelPendingChange({ subId: sub.id }))}>Cancel </button>
                        </div>
                    </>}
            </div>
        </div>
    )
}

export default SubscriptionPage;

